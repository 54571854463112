import * as React from 'react';
import ContactFormModal from '../components/ContactFormModal';

export const Pricing = () => {

    let [showContactForm, setShowContactForm] = React.useState(false)

    return (
        <div className='pricing-page'>
            <div className='title-section' style={{ height: 'calc(100vh - 240px)' }}>
                <div>
                    <div>
                        <h1 className='sv-grey'>Priced To Fit Your Needs</h1>
                        <h3 className='sv-grey'>For pricing information, please reach out to us</h3>
                        <br/>
                        <div className='btn btn-lg btn-primary' onClick={() => setShowContactForm(true)}>Contact Us</div>

                        {/*<a id='you-gotta-be-kidding' href="pricing/#contact" className='btn btn-md btn-primary'>Learn More</a>*/}
                    </div>
                    <img className='fade-in is-visible' height='400px' src='/images/InandOut Hemet-8.jpg' />
                </div>
            </div>
            {/*<div id='contact'>
                <h1>Pricing</h1>
                <h5>For pricing information, please reach out to us</h5>
            </div>*/}
            {showContactForm && <ContactFormModal close={() => setShowContactForm(false)} />}
        </div>
    )
}