import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './pages/Home';
import { Pricing } from './pages/Pricing'

import './custom.css'
import './nav.css'
import './title.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/pricing' component={Pricing} />
    </Layout>
);
