import * as React from 'react';

interface State {
    submitted: boolean;
    error: boolean;
    name: string;
    email: string;
    phone: string;
    comments: string;
}

export default class ContactForm extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            submitted: false,
            error: false,
            name: '',
            email: '',
            phone: '',
            comments: ''
        }
    }

    _handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { state } = this;
        this.setState({ ...state, [e.currentTarget.name]: e.currentTarget.value })
    }

    _submit = () => {
        const { name, phone, email, comments } = this.state;

        if (name === '') alert('Name required');
        if (email === '') alert('Email Address required');

        const model = { name, email, phone, comments }

        fetch(`api/Contact/Post`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model)
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (!resOk) alert(data.errors);                
            })
    }

    render() {
        const { submitted, error, name, phone, email, comments } = this.state
        
        return (
            <section className="form1 contact-form" id="contact-form">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="title col-12 col-lg-8">
                            <h2 className="align-center pb-3 mbr-fonts-style display-2 sv-blue" style={{ fontSize: '32px', fontFamily: 'Franklin Gothic Heavy' }}><p><strong>&nbsp; &nbsp;PRICED TO FIT YOUR NEEDS</strong><br /></p></h2>
                            <h3 className="align-center mbr-light pb-3 mbr-fonts-style display-7 sv-grey" style={{ fontSize: '16px', fontWeight: 200 }}>For pricing information, or any other questions, please reach out to us.</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="media-container-column col-lg-8" data-form-type="formoid">
                            <div className="row">
                                <div hidden={!submitted} data-form-alert="" className="alert alert-success col-12">Thanks for filling out the form!</div>
                                <div hidden={!error} data-form-alert-danger="" className="alert alert-danger col-12"></div>
                            </div>
                            <div className="dragArea row">
                                <div className="col-md-4 form-group" data-for="name">
                                    <label className="form-control-label display-7 sv-grey">Name</label>
                                    <input type="text" value={name} name="name" data-form-field="Name" required={true} className="form-control display-7" id="name-form1-a" onChange={this._handleChange}/>
                                </div>
                                <div className="col-md-4  form-group" data-for="email">
                                    <label className="form-control-label display-7 sv-grey">Email</label>
                                    <input type="email" value={email} name="email" data-form-field="Email" required={true} className="form-control display-7" id="email-form1-a" onChange={this._handleChange} />
                                </div>
                                <div data-for="phone" className="col-md-4  form-group">
                                    <label className="form-control-label display-7 sv-grey">Phone</label>
                                    <input type="tel" value={phone} name="phone" data-form-field="Phone" className="form-control display-7" id="phone-form1-a" onChange={this._handleChange}/>
                                </div>
                                <div data-for="message" className="col-md-12 form-group">
                                    <label className="form-control-label display-7 sv-grey">Message</label>
                                    <textarea name="comments" value={comments} data-form-field="Message" className="form-control display-7" id="comments-form1-a" onChange={this._handleChange}></textarea>
                                </div>
                                <div className="col-md-12 input-group-btn align-center">
                                    <button className="btn btn-form btn-secondary display-7" onClick={this._submit}>SEND FORM</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}