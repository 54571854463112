import * as React from 'react';

import './ContactForm.css';
import { check_phone_format } from '../helpers';

interface State {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    company: string;
    comments: string;
}

interface Props {
    close: () => void
}

export default class ContactFormModal extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            comments: ''
        }
    }

    _handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { state } = this;
        this.setState({ ...state, [e.currentTarget.name]: e.currentTarget.value })
    }

    _submit = () => {
        const { firstName, lastName, email, phone, company, comments } = this.state;

        if (firstName === '') alert('First Name required');
        if (lastName === '') alert('Last Name required');
        if (email === '') alert('Email Address required');

        const model = { firstName, lastName, email, phone, company, comments }

        fetch(`api/Contact/Post`,
            {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify(model)
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (!resOk) alert(data.errors);
                else this.props.close();
            })
    }

    render() {
        const { firstName, lastName, email, phone, company, comments } = this.state;

        return (
            <div className='modal fade show contact-modal' role='dialog'>
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h2>Contact Us</h2>
                            <h4>Call (phone number?) to speak with us</h4>                            
                            <div className='contact-form'>
                                <p><small>* denotes a required field</small></p>
                                <div className='form-line'>
                                    <input name='firstName' value={firstName} onChange={this._handleChange} placeholder='First Name *' />                                    
                                </div>
                                <div className='form-line'>
                                    <input name='lastName' value={lastName} onChange={this._handleChange} placeholder='Last Name *' />
                                </div>
                                <div className='form-line'>
                                    <input name='email' value={email} type='email' onChange={this._handleChange} placeholder='Work Email *' />
                                </div>
                                <div className='form-line'>
                                    <input name='phone' value={phone} onChange={this._handleChange} placeholder='Work Phone Number' onKeyUp={check_phone_format} />
                                </div>
                                <div className='form-line'>
                                    <input name='company' value={company} onChange={this._handleChange} placeholder='Company Name' />
                                </div>
                                <div className='form-line'>
                                    <textarea name='comments' value={comments} rows={3} onChange={this._handleChange} placeholder='Comments / Questions' />
                                </div>
                            </div>
                            <br />
                            <div className='contact-modal-btns'>
                                <div className='btn btn-lg btn-outline-success' onClick={this._submit}>Submit</div>
                                &nbsp; &nbsp;
                                <div className='btn btn-lg btn-outline-secondary' onClick={() => this.props.close()}>Cancel</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}