const dash_key = 189,
    delete_key = [8, 46],
    direction_key = [33, 34, 35, 36, 37, 38, 39, 40];

const check_for_numpad = (code: number) => {
    return (code >= 96 && code <= 105) ?
        code - 48 :
        code;
}

function do_phone_format(ele: any, restore: any) {
    var new_number,
        selection_start = ele.selectionStart,
        selection_end = ele.selectionEnd,
        number = ele.value.replace(/\D/g, '');

    // auto-add dashes
    if (number.length > 2) {
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
            new_number += number.substring(3);
        }
        else if (number.length > 5) {
            new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
            new_number += number.substring(6);
        }
    }
    else {
        new_number = number;
    }

    ele.value = new_number.length > 12 ? new_number.substring(0, 12) : new_number;

    // put cursor back where it was
    if (new_number.slice(-1) === '-' && restore === false
        && (new_number.length === 8 && selection_end === 7)
        || (new_number.length === 4 && selection_end === 3)) {
        selection_start = new_number.length;
        selection_end = new_number.length;
    }
    else if (restore === 'revert') {
        selection_start--;
        selection_end--;
    }

    ele.setSelectionRange(selection_start, selection_end);
}

export const check_phone_format = (e: React.KeyboardEvent<HTMLInputElement>) => {
    var field = e.currentTarget,
        key_code = check_for_numpad(e.keyCode),
        key_string = String.fromCharCode(key_code),
        press_delete = false,
        selection_end = field.selectionEnd || 0;

    console.log(key_code)
    console.log(key_string)

    if (delete_key.indexOf(key_code) > -1)
        press_delete = true;

    if (key_string.match(/^\d+$/) || press_delete)
        do_phone_format(field, press_delete);
    else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
    }
    else if (key_code === dash_key) {
        if (selection_end === field.value.length) {
            field.value = field.value.slice(0, -1);
        }
        else {
            field.value = field.value.substring(0, selection_end - 1) + field.value.substring(selection_end);
            field.selectionEnd = selection_end - 1;
        }
    }
    else {
        e.preventDefault();
        do_phone_format(field, 'revert');
    }
}