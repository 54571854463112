import * as React from 'react'
import { NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import ContactFormModal from './ContactFormModal';

export const Footer = () => {

    let [showContactForm, setShowContactForm] = React.useState(false)

    return (
        <footer className='page-footer font-small sv-grey'>
            <div /*className='container container-fluid text-center text-md-left'*/>
                <div className="links">
                    <div className='left-links'>
                        {/*<NavLink className='sv-grey' to="/tos" tag={Link}>Terms of Service</NavLink>
                        <NavLink className='sv-grey' to="/privacy" tag={Link}>Privacy Policy</NavLink>*/}
                    </div>
                    <div className='right-links'>
                        {/*<NavLink className='sv-grey' to="/linkedin" tag={Link}>Linkedin</NavLink>*/}
                        {/*<NavLink className='sv-grey' tag={Link} href='#contact-form'>Contact Us</NavLink>*/}
                    </div>
                </div>
            </div>
            <div className='footer-copyright py-3' /*className="footer-copyright text-center py-3"*/>© 2021 Copyright: Core States SiteVue</div>
            {showContactForm && <ContactFormModal close={() => setShowContactForm(false)} />}
        </footer>
    )
};