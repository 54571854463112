import * as React from 'react';
import { toggleAnimationClassObserver, isIE11, elementInViewport } from '../animationHelpers';
import ContactForm from '../components/ContactForm';

const scroll = window.requestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 600) }

const loop = () => {
    const targets = document.querySelectorAll('.fade-in, .delay-1, .delay-2, .delay-3, .delay-4');
    targets.forEach((el) => {
        if (elementInViewport(el)) el.classList.add('is-visible')
        else if (!el.classList.contains('keep')) el.classList.remove('is-visible');
    })

    scroll(loop)
}

export default class Home extends React.PureComponent<any> {

    componentDidMount() {
        //this._realignRowsWithIcons();
        //loop();
    }

    //_realignRowsWithIcons = () => {
    //    const featureIcons = document.querySelector('.feature-icons') as HTMLDivElement,
    //        addtl = document.querySelector('.additional-features') as HTMLDivElement,
    //        pageContainer = document.querySelector('.home-page') as HTMLDivElement

    //    addtl.style.marginLeft = (featureIcons.offsetLeft - pageContainer.offsetLeft) + 'px';
    //}

    render() {

        return (
            <div className='home-page'>
                <section className="header1 cid-s4Dg8YcqWp mbr-parallax-background" id="header1-1" style={{ zIndex: 0, backgroundImage: 'none', position: 'relative' }}>
                    <div className="mbr-overlay" style={{ opacity: 0.7, backgroundColor: 'rgb(71, 71, 71)' }}></div>
                    <div className="">
                        <div className="row justify-content-md-center">
                            <div className="mbr-white col-md-10">
                                <div className='title-text'>
                                    <h1 className="mbr-section-title align-center mbr-bold pb-3 mbr-fonts-style display-1">
                                        DELIVER <br />A <span className='sv-green fade-in' style={{ fontFamily: 'Franklin Gothic Book' }}>BETTER</span> <br />PROJECT.
                                </h1>
                                    <p className="mbr-text align-center pb-3 mbr-fonts-style display-5">
                                        From site survey to project closeout, <br />Core States SiteVue is a milestone-driven <br />software that offers flexible, organized <br />reporting with access at your fingertips.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="jarallax-container-0" style={{ clip: 'rect(0, 2100px, 578px, 0)', position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', zIndex: -100 }}>
                        <div style={{ backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: 'url("/images/projectmanageronsitewithipad-edit-2000x1333.jpg")', position: 'fixed', top: '0px', left: '0px', width: '100%', height: '665.4px', overflow: 'hidden', pointerEvents: 'none', marginTop: '85.8px', transform: 'translate3d(0px, -85.8px, 0px)' }}></div>
                    </div>
                </section>

                <section className="features1 cid-s4DFB0ueq5" id="features1-5">
                    <div className="container">
                        <div className="media-container-row">
                            <div className="card p-3 col-12 col-md-6 col-lg-3">
                                <div className="card-img pb-3">
                                    <img style={{ height: '96px' }} src='/icons/DocumentManagement_circleicon-blackneutral.png' />
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title py-3 mbr-fonts-style display-5 sv-blue">DOCUMENT MANAGEMENT</h4>
                                    <p className="mbr-text mbr-fonts-style display-7 sv-grey">
                                        Quickly reference files in the field that have been uploaded in custom project folders.
                                    </p>
                                </div>
                            </div>
                            <div className="card p-3 col-12 col-md-6 col-lg-3">
                                <div className="card-img pb-3">
                                    <img style={{ height: '96px' }} src='/icons/Milestone_circleicon-blackneutral.png' />
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title py-3 mbr-fonts-style display-5 sv-blue">
                                        MILESTONE TRACKING</h4>
                                    <p className="mbr-text mbr-fonts-style display-7 sv-grey">
                                        Identify important milestones on multiple projects simultaneously.
                                    </p>
                                </div>
                            </div>
                            <div className="card p-3 col-12 col-md-6 col-lg-3">
                                <div className="card-img pb-3">
                                    <img style={{ height: '96px' }} src='/icons/FieldReports_circleicon-blackneutral.png' />
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title py-3 mbr-fonts-style display-5 sv-blue">
                                        FIELD <br />REPORTS</h4>
                                    <p className="mbr-text mbr-fonts-style display-7 sv-grey">
                                        Guides the user to document daily progress, photos, on-site visitors, equipment, and automated weather report.
                                    </p>
                                </div>
                            </div>
                            <div className="card p-3 col-12 col-md-6 col-lg-3">
                                <div className="card-img pb-3">
                                    <img style={{ height: '96px' }} src='/icons/Q&A_circleicon-blackneutral.png' />
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title py-3 mbr-fonts-style display-5 sv-blue">
                                        PROCESS <br />RFIS</h4>
                                    <p className="mbr-text mbr-fonts-style display-7 sv-grey">
                                        A single location to ask questions and provide solutions. Teammates outside of CoreStates SiteVue can also collaborate.
                                    </p>
                                </div>
                            </div>
                            <div className="card p-3 col-12 col-md-6 col-lg-3">
                                <div className="card-img pb-3">
                                    <img style={{ height: '96px' }} src='/icons/Submittals_circleicon-blackneutral.png' />
                                </div>
                                <div className="card-box">
                                    <h4 className="card-title py-3 mbr-fonts-style display-5 sv-blue">
                                        EXPEDITE <br />SUBMITTALS</h4>
                                    <p className="mbr-text mbr-fonts-style display-7 sv-grey">
                                        Streamlined process to upload and submit documents for approval.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features11 cid-s4DKjvgXBb" id="features11-9">
                    <div className="container" style={{ maxWidth: '1240px' }}>
                        <div className="col-md-12">
                            <div className="media-container-row">
                                <div className='mobile-screenshots' style={{ width: '50%', paddingRight: '55%' }}>
                                    <img height='380px' width='640px' src='/images/SiteVue-Tablet v2.png' />
                                    <img height='400px' src='/images/SiteVue-Phone.png' />
                                </div>
                                <div className=" align-left aside-content">
                                    <h2 className="pt-2 sv-blue" style={{ fontSize: '1em', fontFamily: 'Franklin Gothic Heavy' }}><strong>ADDITIONAL FEATURES</strong></h2>
                                    <div className="mbr-section-text">
                                        <p className="mbr-text pt-3 mbr-light mbr-fonts-style display-7 sv-grey" style={{ fontFamily: 'Arial' }}>
                                            Our cloud-based platform will be with you at any time throughout the entire project lifecycle,
                                            ensuring you never miss a beat and can stay focused on what matters most to your organization: delivering a better project.
                                        </p>
                                    </div>

                                    <div className="block-content sv-grey" style={{ fontFamily: 'Arial' }}>
                                        <div className="">
                                            <div className="media">
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Build-your-own-graphs dashboard.</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="media">                                               
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Map identifying active project locations.</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="media">                                                
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Redline report to alert upcoming milestones.</h4>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="">
                                            <div className="media">
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Field reports document official project record.</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="media">
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Reports download into a custom template.</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="media">
                                                <div className="media-body">
                                                    <h4 className="card-title mbr-fonts-style display-7 sv-grey" style={{ fontSize: '1rem' }}>
                                                        <img style={{ height: '1rem' }} src='/icons/Checkmark_circleicon.png' />
                                                        &nbsp;
                                                        Project archives configurable on a per-client basis.</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactForm />
                <div className=''>
                    <div className="media-container-row bottom-banner">
                        <div className="">
                            <div className="media-wrap">
                                <a href="https://mobirise.co/">
                                    <img height='60px' src="/images/corestatessitevue_logo_grey_200812.jpg" alt="Mobirise" title="" />
                                </a>                                
                            </div>
                        </div>
                        <div className="col-md-9">
                            <p className="mbr-text align-right links mbr-fonts-style display-7"></p>
                        </div>
                    </div>
                </div>
                {/*<div className='title-section'>
                    <h1 className='fade-in keep sv-grey'>CoreStates SiteVue</h1>
                    <div className='subtitle'>
                        <div className='delay-1 keep sv-grey'>Deliver&nbsp;</div>
                        <div className='delay-2 keep sv-grey'>A&nbsp;</div>
                        <div className='delay-3 keep sv-blue'>Better&nbsp;</div>
                        <div className='delay-4 keep sv-grey'>Project&nbsp;</div>
                    </div>
                    <div className='fade-in'>
                        <img src='/images/ProjectManagerOnSiteWithIpad.jpg' />                        
                    </div>
                </div>*/}

                {/*<div className='summary-block'>
                    <h1 className='fade-in sv-grey'>The Cloud In Your Pocket</h1>
                    <div className='delay-1 sv-white'>From site survey to project closeout, CoreStates SiteVue is a user-driven software that offers flexible, organized data with access at your fingertips</div>
                    <div className='delay-1 sv-white'>Our cloud-based platform will be with you at any time throughout the entire project lifecycle, ensuring you never miss a beat a can stay focused on what matters most to your organization: delivering a <span className='sv-green'>better</span> project</div>
                    <div className='delay-1 keep' style={{ position: 'relative' }}>
                        <img height='300px' src='/images/SiteVueShot1.png' />
                        <img height='350px' src='/images/SiteVueShot2.png' />
                    </div>
                </div>*/}

                {/*<div className='content-row feature-icons fade-in'>
                    <div>
                        <img src='/icons/DocumentManagement_icon-blackneutral.png' />
                        <label className='sv-blue'>Document Management</label>
                        <div>Quickly reference files in the field that have been uploaded in custom project folders.</div>
                    </div>
                    <div>
                        <img src='/icons/Milestone_icon-blackneutral.png' />
                        <label className='sv-blue'>Milestone Tracking</label>
                        <div>Identify important milestones on multiple projects simultaneously.</div>
                    </div>
                    <div>
                        <img src='/icons/FieldReports_icon-blackneutral.png' />
                        <label className='sv-blue'>Field Reports</label>
                        <div>Guides the user to document daily progress, photos, on-site visitors, and automated weather reporting.</div>
                    </div>
                    <div>
                        <img src='/icons/Q&A_icon-blackneutral.png' />
                        <label className='sv-blue'>Expedite RFIs</label>
                        <div>A single location to ask questions and provide solutions. Teammates outside of SiteVue can also collaborate.</div>
                    </div>
                    <div>
                        <img src='/icons/Submittals_icon-blackneutral.png' />
                        <label className='sv-blue'>Submittals</label>
                        <div>Streamlined process to upload and submit documents for approval.</div>
                    </div>
                </div>

                <div className='fade-in fade-2 content-row additional-features left-align'>
                    <div className='col-md-6'>
                    <label className='sv-blue'>Additional Features</label>
                    <div>Map Idenfitying active project locations</div>
                    <div>Redline report to easily idenfity urgent work items</div>
                    <div>Highly configurable, from building project teams to personalized columns</div>
                        <div>Daily email reminders to all staff of any out-standing work</div>
                    </div>
                    <div className='col-md-6'>
                        <img height='120px' src='/images/phone.jpg' />
                    </div>
                </div>*/}
            </div>
        )
    }
}

//export default connect()(Home);
